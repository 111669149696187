<ngx-guided-tour
        skipText="Exit Tutorial"
        [tourStepWidth]="'350'"
        [minimalTourStepWidth]="'350'"
></ngx-guided-tour>

<nz-layout>
    <nz-sider *ngIf="globals?.userDetail" nzWidth="80px">
        <img class="brand-logo" src="assets/brand-logo.png"/>
        <div *ngIf="hasAccess('download_reports')" class="menu-item" (click)="navigate('/download-reports')"
             [ngClass]="activeRoute === '/download-reports' ? 'selected' : ''">
            <!-- <div *ngIf="hasAccess('download_reports')" class="menu-item" (click)="navigate('/download-reports')" [ngClass]="globals.userDetail.role !== 'admin' ? 'disable' : activeRoute === '/download-reports' ? 'selected' : ''"> -->
            <i class="item" nz-icon nzType="download" nzTheme="outline"></i>
            <div class="item">Download</div>
        </div>
        <div *ngIf="hasAccess('upload')" class="menu-item" (click)="navigate('/upload-data')"
             [ngClass]="activeRoute === '/upload-data' ? 'selected' : ''">
            <!-- <div *ngIf="hasAccess('upload')" class="menu-item" (click)="navigate('/upload-data')" [ngClass]="globals.userDetail.role !== 'admin' ? 'disable' : activeRoute === '/upload-data' ? 'selected' : ''"> -->
            <i class="item" nz-icon nzType="upload" nzTheme="outline"></i>
            <div class="item">Upload</div>
        </div>
        <div *ngIf="hasAccess('dashboard')" class="menu-item" (click)="navigate('/dashboard')"
             [ngClass]="activeRoute === '/dashboard' ? 'selected' : ''">
            <!-- <div *ngIf="hasAccess('dashboard')" class="menu-item" (click)="navigate('/dashboard')" [ngClass]="globals.userDetail.role !== 'admin' ? 'disable' : activeRoute === '/dashboard' ? 'selected' : ''"> -->
            <i class="item" nz-icon nzType="fund" nzTheme="fill"></i>
            <div class="item">Dashboard</div>
        </div>
        <div *ngIf="hasAccess('affex')" class="menu-item" (click)="navigate('/affex')"
             [ngClass]="activeRoute === '/affex' ? 'selected' : ''">
            <!-- <div *ngIf="hasAccess('affex')" class="menu-item" (click)="navigate('/affex')" [ngClass]="globals.userDetail.role !== 'admin' ? 'disable' : activeRoute === '/affex' ? 'selected' : ''"> -->
            <i class="item" nz-icon nzType="swap" nzTheme="outline"></i>
            <div class="item">AFFEX</div>
        </div>
        <div *ngIf="hasAccess('subscriptions')" class="menu-item" (click)="navigate('/subscriptions')"
             [ngClass]="activeRoute === '/subscriptions' ? 'selected' : ''">
            <i class="item" nz-icon nzType="mail" nzTheme="outline"></i>
            <div class="item">Subscriptions</div>
        </div>
        <!-- <div class="menu-item" (click)="toggleSmartAssist()"
             [ngClass]="activeRoute === '/smart-assist' ? 'selected' : ''">
             <span class="item" nz-icon nzType="question-circle" nzTheme="outline"></span>
            <div class="item">Smart Assist</div>
        </div> -->
        <div class="menu-item"
            (click)="toggleSmartAssist()"
            [ngClass]="activeRoute === '/smart-assist' ? 'selected' : ''"
            [class.disabled]="activeRoute === '/smart-assist'">
            <span class="item" nz-icon nzType="question-circle" nzTheme="outline"></span>
            <div class="item">Smart Assist</div>
        </div>

        <div *ngIf="hasAccess('use cases')" class="menu-item" (click)="navigate('/usecases')"
             [ngClass]="activeRoute === '/usecases' ? 'selected' : ''">
            <!-- <div *ngIf="hasAccess('use cases')" class="menu-item" (click)="navigate('/usecases')" [ngClass]="globals.userDetail.role !== 'admin' ? 'disable' : activeRoute === '/usecases' ? 'selected' : ''"> -->
            <i class="item" nz-icon nzType="audit" nzTheme="outline"></i>
            <div class="item">Use Cases</div>
        </div>
        <div *ngIf="hasAccess('admin')" class="menu-item" (click)="navigate('/admin')"
             [ngClass]="activeRoute === '/admin' ? 'selected' : ''">
            <!-- <div *ngIf="hasAccess('admin')" class="menu-item" (click)="navigate('/admin')" [ngClass]="globals.userDetail.role !== 'admin' ? 'disable' : activeRoute === '/admin' ? 'selected' : ''"> -->
            <i class="item" nz-icon nzType="setting" nzTheme="fill"></i>
            <div class="item">Admin <span class="pending-count"
                                          *ngIf="adminPendingCount !== 0 && globals.userDetail.role === 'manager'">{{adminPendingCount}}</span>
            </div>
        </div>

        <div class="menu-item footer">
            <div class="footer-item" [ngClass]="activeRoute === '/idmapps' ? 'selected' : ''">
                <i nz-icon nzType="appstore" nzTheme="fill" (click)="navigate('/idmapps')"></i>
            </div>
            <div class="footer-item" [ngClass]="activeRoute === '/profile' ? 'selected' : ''">
                <i nz-icon nzType="user" nzTheme="outline" (click)="navigate('/profile')"></i>
            </div>
            <div [ngClass]="activeRoute === '/faq' ? 'selected' : ''">
                <i nz-icon nzType="question-circle" nzTheme="outline" (click)="navigate('/faq')"></i>
            </div>
        </div>
    </nz-sider>
    <nz-layout class="right-layout" (scroll)="scrollHandler($event)">
        <ng-container *ngIf="globals?.userDetail">
            <nz-header>
                <div class="app-branding">AFFINITY MONITOR® <br> ACCESS MONITORᵀᴹ</div>
                <div class="app-header">{{ getHeader() }}</div>
                <!--      <div class="header-icons" (click)="navigate('/faq')" [ngClass]="highLight ? 'highlight' : ''">-->
                <!--        <i nz-icon nzType="exclamation-circle" nzTheme="fill"></i>-->
                <!--        <div>FAQ</div>-->
                <!--      </div>-->
            </nz-header>
            <nz-divider></nz-divider>
        </ng-container>
        <nz-content class="data-container">
             <!-- Always keep Smart Assist in the DOM -->
             <app-smart-assist
             [class.hidden]="!isSmartAssistVisible"
             class="smart-assist-wrapper"
         ></app-smart-assist>
         <router-outlet *ngIf="!isSmartAssistVisible"></router-outlet>
        </nz-content>
    </nz-layout>
</nz-layout>
<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item (click)="logout()">Logout</li>
    </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #helpMenu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item (click)="restartTour()">Onboard me again</li>
    </ul>
</nz-dropdown-menu>

<app-onboarding
        *ngIf="!isOnBoarded"
        [showTour]="!isOnBoarded"
        (hideOnboarding)="onOnboardingClose($event)"
>
</app-onboarding>
