import { Injectable, OnDestroy } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AppState} from './app-state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private appStateSubscription: Subscription;
  public userDetails;

  constructor(private appState: AppState, private router: Router) {
    this.appStateSubscription = AppState.appState.subscribe((val) => {

      if (!this.userDetails) {
        if (val && val.userDetails) {
          this.userDetails = val.userDetails;
        }
      }

    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log(route.url.length, 'Route url:-', route.url)
    console.log('user details :- ', this.userDetails)

    if (this.userDetails) {
      if (this.userDetails.client !== route.url[0].path || route.url.length !== 2) {
        if (this.userDetails.role === 'admin') {
          this.appState.setGlobalState('activeRoute', '/admin');
          this.router.navigateByUrl(this.userDetails.client + '/admin');
        } else {
          this.appState.setGlobalState('activeRoute', '/upload-data');
          this.router.navigateByUrl(this.userDetails.client + '/upload-data');
        }
        // Return false to prevent access to the original route
        return false;
      }
      // If checks pass, allow the route activation
      return true;
    }
    // If userDetails is not available, deny access
    return false;
  }

  ngOnDestroy() {
    if (this.appStateSubscription) {
      this.appStateSubscription.unsubscribe();
    }
  }
}