<div class="faq-wrapper">
  <div class="page-header">Frequently Asked Questions</div>
  <div nz-row nzJustify="space-between" nzAlign="middle">
    <div nz-col nzSpan="12" class="search-wrapper">
      <app-search-bar
        [searchParameter]="searchParamData"
        (searchValue)="filterOnSearch($event)"
        (noResultFound)="formatOnNoresult($event)"
        (noInput)="resetSearch($event)"
        (resetSearch)="resetSearch($event)"
        (inputKey)="filterOnSearch($event)"
        [(inputValue)]="searchInput"
      ></app-search-bar>
    </div>
    <div nz-col nzSpan="12" class="button-wrapper">
      <span class="export-wrapper">
        <app-simple-dropdown [page]="'faq'" *ngIf="isAdmin"></app-simple-dropdown>
      </span>
      <button nz-button nzType="primary" class="app-primary-button" style="height: 38px; padding: 0px 10px; cursor: pointer;"
        (click)="downloadUsecase()">
        <i nz-icon nzType="download" nzTheme="outline"></i>
        Illustrated Applications
      </button>
      <a class="onboarding-button" (click)="showOnboarding = true">View Tutorial</a>
    </div>
    <div>
      <ng-container>
        <ul nz-menu nzMode="horizontal">
          <ng-container *ngFor="let item of menuItems">
            <li class="affinity-menu-item" nz-menu-item [nzSelected]="item.isSelected" (click)="switchTab(item.name)">
              {{ item.name }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="spinner" *ngIf="isLoading">
    <nz-spin nzSimple></nz-spin>
  </div>
  <div class="content-wrapper" *ngIf="!isLoading">
    <div *ngIf="noResult" class="no-result">Your search returned no results</div>
    <nz-collapse *ngIf="!noResult">
      <nz-collapse-panel
        *ngFor="let faq of faqData"
        [nzHeader]="faq['Question']"
        class="answer-wrapper"
      >
        <ng-container [ngSwitch]="faq['Question']">
          <ng-container *ngSwitchCase="'What is the data that will be available in the output reports?'">
            The ZS AffinityMonitor®️ output data will have two files:<br> 
            1. First, an HCP-level report which contains aggregated, cross-industry metrics on an individual HCP’s channel affinity and related metrics like channel-wise engagement volume and engagement quintile.<br>
            2. Second, a Summary report consisting of cross-industry, cross-channel customer views.
          </ng-container>

          <ng-container *ngSwitchCase="'What is the data that will be available in the Output reports?'">
            ZS AccessMonitor™️ subscriptions consist of two deliverables:<br> 
            1. HCP data report – a text file containing retrospective HCP accessibility metrics such as the AM No-see rating, call frequency percentiles (overall and channel-wise), industry attainment, etc.<br>
            2. ZAIDYN interactive dashboard – allows viewers to compare AccessMonitor data against current/past industry and client level metrics.
          </ng-container>
          <ng-container *ngSwitchDefault>
            <p>{{ faq['Answer'] }}</p>

            <button *ngIf="faq['Answer']?.includes('The description of each field in the HCP data report is provided in the linked data layout document.  \nIn case of any additional questions, please reach out to AffinityMonitor@zs.com for questions.')"
              type="button"
              (click)="downloadFile(loadedData['template_url_1'], '')"
              nz-button nzType="primary" class="app-primary-button" style="height: 38px; padding: 0px 10px; cursor: pointer;">
              <i nz-icon nzType="download" nzTheme="outline"></i> AFM Output Report Definitions
            </button>

            <button *ngIf="faq['Answer']?.includes(' \n \nAs ZS periodically expands on the HCP-level industry benchmarks that can be reported back to subscribers, the data layout document will be updated to reflect the base set of HCP access metrics, as well as the add-on fields that are available to opt in for an additional cost (e.g. Best day to call). Please reach out to accessmonitor@zs.com for questions.')"
              type="button"
              (click)="downloadFile(loadedData['template_url_2'], '')"
              nz-button nzType="primary" class="app-primary-button" style="height: 38px; padding: 0px 10px; cursor: pointer;">
              <i nz-icon nzType="download" nzTheme="outline"></i> ACM Output Report Definitions
            </button>
          </ng-container>
        </ng-container>

      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>

<app-onboarding [showTour]="showOnboarding" (hideOnboarding)="onOnboardingClose($event)"></app-onboarding>

<app-drawer
  [(showDrawer)]="showDrawer"
  [content]="drawerContent"
  [title]="'HELP CENTER'"
  (tabChanged)="switchDrawerTab($event)">
</app-drawer>
