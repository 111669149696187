import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DownloadReportsComponent } from './download-reports/download-reports.component';
import { UploadDataComponent } from './upload-data/upload-data.component';
import { FaqComponent } from './faq/faq.component';
import { FaqModule } from './faq/faq.module';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SmartAssistComponent } from './smart-assist/smart-assist.component';
import { AffexComponent } from './affex/affex.component';
import { AdminComponent } from './admin/admin.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingPageModule } from './landing-page/landing-page.module';
import { AuthGuard } from './shared/services/auth.guard';
// import { DashboardModule } from './dashboard/dashboard.module';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { UseCasesComponent } from './use-cases/use-cases.component';
import { IdmAppsModule } from './idm-apps/idm-apps.module';
import { IdmAppsComponent } from './idm-apps/idm-apps.component';
import {ClientSelectionComponent} from './client-selection/client-selection.component';
import {PowerBiReportComponent} from './power-bi-report/power-bi-report.component';

const routes: Routes = [
  { path: ':client', redirectTo: ':client/profile', pathMatch: 'full' },
  { path: ':client/download-reports', component: DownloadReportsComponent, canActivate: [AuthGuard] },
  { path: ':client/upload-data', component: UploadDataComponent, canActivate: [AuthGuard] },
  { path: ':client/faq', component: FaqComponent, canActivate: [AuthGuard] },
  { path: ':client/subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard]},
  { path: ':client/smart-assist', component: SmartAssistComponent, canActivate: [AuthGuard]},
  { path: ':client/affex', component: AffexComponent, canActivate: [AuthGuard]},
  { path: ':client/admin', component: AdminComponent, canActivate: [AuthGuard]},
  { path: ':client/dashboard', component: PowerBiReportComponent, canActivate: [AuthGuard]},
  { path: ':client/usecases', component: UseCasesComponent, canActivate: [AuthGuard]},
  { path: ':client/profile', component: LandingPageComponent, canActivate: [AuthGuard]},
  { path: ':client/idmapps', component: IdmAppsComponent, canActivate: [AuthGuard]},
  { path: 'afm', component: LandingPageComponent, canActivate: [AuthGuard]},
  { path: '', component: ClientSelectionComponent},
  // { path: '**', redirectTo: 'afm'}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { useHash: false }), FaqModule, LandingPageModule, IdmAppsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
