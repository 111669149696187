import { NgModule,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SmartAssistComponent } from './smart-assist.component';
import { HttpClientModule } from '@angular/common/http';
// import SmartAssist from '../../../node_modules/zpf-smart-assist/src/smartAssist.m.js';
// import * as SmartAssist from '../../../node_modules/zpf-smart-assist/src/smartAssist.m.js';


@NgModule({
  declarations: [
    SmartAssistComponent
  ],
  exports: [SmartAssistComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    // SmartAssist
  ],
  providers: [],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SmartAssistModule { }
