import { Component, AfterViewInit, OnInit, OnDestroy, Renderer2  } from '@angular/core';
import * as SmartAssist from '../../../node_modules/zpf-smart-assist/src/smartAssist.m.js';

@Component({
  selector: 'app-smart-assist',
  templateUrl: './smart-assist.component.html',
  styleUrls: ['./smart-assist.component.scss']
})

export class SmartAssistComponent implements AfterViewInit, OnDestroy {
  
  constructor(private renderer: Renderer2) {}
  isVisible = true; 
  
  // Method to show Smart Assist
  show(): void {
    this.isVisible = true;
  }

  // Method to hide Smart Assist
  hide(): void {
    this.isVisible = false;
  }

  // This will be called after the component's view is initialized
  ngAfterViewInit(): void {
    this.addBodyClass();
  }

  // Add the class to the body when the component is loaded
  addBodyClass(): void {
    this.renderer.addClass(document.body, 'zs-master-style');
  }

  // Clean up by removing the class when the component is destroyed
  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'zs-master-style');
  }
}

// import { Component, OnDestroy, Renderer2 } from '@angular/core';
// import { Router } from '@angular/router';

// @Component({
//   selector: 'app-smart-assist',
//   templateUrl: './smart-assist.component.html',
//   styleUrls: ['./smart-assist.component.scss']
// })
// export class SmartAssistComponent implements OnDestroy {
//   isVisible = true;

//   constructor(private renderer: Renderer2, private router: Router) {}

//   ngAfterViewInit(): void {
//     this.initializeSmartAssistListeners();
//   }

//   private initializeSmartAssistListeners(): void {
//     console.log('Initialize Function')
//     const smartAssist = (window as any).zpfSmartAssist;
//     console.log('Initialize:-', smartAssist)
//     if (smartAssist && smartAssist.on) {
//       smartAssist.on('chaterror', (error: any) => {
//         console.error('Smart Assist encountered an error:', error);
//         if (error.status === 401) {
//           this.handleSessionTimeout();
//         }
//       });
//     }
//   }

//   private handleSessionTimeout(): void {
//     console.log('Session timed out. Hiding Smart Assist and redirecting to login...');
//     this.hide();
//     this.router.navigate(['/login']);
//   }

//   show(): void {
//     this.isVisible = true;
//   }

//   hide(): void {
//     this.isVisible = false;
//   }

//   ngOnDestroy(): void {
//     // Clean up event listeners if needed
//     const smartAssist = (window as any).zpfSmartAssist;
//     if (smartAssist && smartAssist.off) {
//       smartAssist.off('chaterror');
//     }
//     this.renderer.removeClass(document.body, 'zs-master-style');
//   }
// }

