import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NavigationModule } from './navigation/navigation.module';
import { AdminModule } from './admin/admin.module';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SharedModule } from './shared/shared.module';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import {
  DownloadOutline,
  VerticalLeftOutline,
  VerticalRightOutline,
  WarningOutline,
  ExclamationCircleFill,
  UserOutline,
  AppstoreFill,
  QuestionCircleFill,
  PlusOutline,
  HomeFill,
  SettingFill,
  MailOutline,
  ShoppingFill,
  TeamOutline,
  ImportOutline,
  CloudDownloadOutline,
  SolutionOutline,
  DollarOutline,
  PlusCircleOutline,
  EyeFill,
  EditOutline,
  DeleteOutline,
  CheckCircleFill,
  LoadingOutline,
  ExclamationCircleOutline,
  CloseCircleFill,
  ReloadOutline,
  CloudUploadOutline,
  ArrowLeftOutline,
  CopyOutline,
  MailFill,
  FilterFill,
  CaretRightOutline,
  FundFill,
  SwapOutline,
  ClockCircleFill,
  FileSearchOutline,
  MinusCircleFill,
  CheckCircleOutline,
  FileFill,
  AuditOutline,
  QuestionOutline,
  RollbackOutline,
  MinusOutline
} from '@ant-design/icons-angular/icons';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';

// update ant design language to english
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { AppRoutingModule } from './app-routing.module';
import {ClientSelectionModule} from './client-selection/client-selection.module';
import {PowerBiReportModule} from './power-bi-report/power-bi-report.module';
import {SmartAssistModule} from './smart-assist/smart-assist.module';

const icons: IconDefinition[] = [
  DownloadOutline,
  VerticalLeftOutline,
  VerticalRightOutline,
  WarningOutline,
  ExclamationCircleFill,
  UserOutline,
  AppstoreFill,
  QuestionCircleFill,
  PlusOutline,
  HomeFill,
  SettingFill,
  MailOutline,
  ShoppingFill,
  TeamOutline,
  ImportOutline,
  CloudDownloadOutline,
  SolutionOutline,
  DollarOutline,
  PlusCircleOutline,
  EyeFill,
  EditOutline,
  DeleteOutline,
  CheckCircleFill,
  LoadingOutline,
  ExclamationCircleOutline,
  CloseCircleFill,
  ReloadOutline,
  CloudUploadOutline,
  ArrowLeftOutline,
  CopyOutline,
  MailFill,
  FilterFill,
  CaretRightOutline,
  FundFill,
  SwapOutline,
  ClockCircleFill,
  FileSearchOutline,
  MinusCircleFill,
  CheckCircleOutline,
  FileFill,
  AuditOutline,
  QuestionOutline,
  RollbackOutline,
  MinusOutline
];

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NzLayoutModule,
    HttpClientModule,
    NzDividerModule,
    NzPopoverModule,
    NzIconModule.forRoot(icons),
    SharedModule,
    NzSpaceModule,
    NzCarouselModule,
    BrowserAnimationsModule,
    NavigationModule,
    GuidedTourModule,
    AppRoutingModule,
    NzDropDownModule,
    NzGridModule,
    NzCardModule,
    AdminModule,
    ClientSelectionModule,
    PowerBiReportModule,
    SmartAssistModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    GuidedTourService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
})
export class AppModule {}
